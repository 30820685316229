<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Facturación</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tep</li>
                  <li class="breadcrumb-item active">Facturación</li>
                  <li class="breadcrumb-item active">Facturación</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="col-3 pt-2 pb-2">
                  <h5 class="mb-0">Formulario {{ accion }}</h5>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="form-group col-md-4">
                      <label>Nombre</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="form.nombre"
                        :class="
                          $v.form.nombre.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        :disabled="definitivo == 1"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label>Fecha inicial</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="form.fecha_inicio"
                        :class="
                          $v.form.fecha_inicio.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @input="validaFechas"
                        :disabled="definitivo == 1"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label>Fecha final</label>
                      <input
                        type="date"
                        class="form-control form-control-sm"
                        v-model="form.fecha_fin"
                        :class="
                          $v.form.fecha_fin.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        @input="validaFechas"
                        :disabled="definitivo == 1"
                      />
                    </div>
                    <div class="form-group col-md-2">
                      <label for="estado">Estado</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="form.estado"
                        :class="
                          $v.form.estado.$invalid ? 'is-invalid' : 'is-valid'
                        "
                        :disabled="definitivo == 1"
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="estado in listasForms.estados"
                          :key="estado.numeracion"
                          :value="estado.numeracion"
                        >
                          {{ estado.descripcion }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label for="observaciones">Observaciones</label>
                      <br />
                      <textarea
                        vtype="text"
                        class="form-control"
                        v-model="form.observaciones"
                        rows="2"
                        :disabled="definitivo == 1"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    @click="save()"
                    v-if="$store.getters.can('tep.facturacion.create')"
                    :disabled="definitivo == 1"
                  >
                    <i class="fas fa-paper-plane"></i>
                    <br />Guardar
                  </button>

                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i>
                    <br />Volver
                  </button>
                </div>
              </div>
            </div>
            <div
              class="container-fluid"
              v-if="detFacturas.length > 0 && detFacturas !== null"
            >
              <div class="card">
                <div class="card-header pt-2 pb-2">
                  <div class="col-3 pt-2 pb-2">
                    <h5 class="mb-0">Filtros</h5>
                  </div>

                  <div class="card-body">
                    <div class="row">
                      <div class="form-group col-md-2">
                        <label for="contrato"> # Contrato</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.contrato"
                        />
                      </div>
                      <div class="form-group col-md-4">
                        <label for="proveedor">Proveedor</label>
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="transportadora"
                          placeholder="Nombre"
                          label="razon_social"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.transportadoras"
                          :filterable="false"
                          @search="buscarTransportadoras"
                          @input="selectTransportadora()"
                        ></v-select>
                      </div>
                      <div class="form-group col-md-4">
                        <label for="remolque">Campo</label>
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="sitio"
                          placeholder="Nombre"
                          label="nombre"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.sitios"
                          :filterable="true"
                          @search="buscarSitio"
                          @input="selectSitio()"
                        ></v-select>
                      </div>

                      <div class="form-group col-md-2">
                        <label for="activo">Activo</label>
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.activo"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="activo in listasForms.activos"
                            :key="activo.numeracion"
                            :value="activo.numeracion"
                          >
                            {{ activo.descripcion }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-md-2">
                        <label for="transportadora">Chequera</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.chequera"
                        />
                      </div>
                      <div class="form-group col-md-2">
                        <label for="contrato"> Ceco /AFE</label>
                        <select
                          v-model="filtros.ceco"
                          placeholder="Ceco AFE"
                          label="nombres"
                          class="form-control form-control-sm p-0"
                        >
                          <option value="">Seleccione...</option>
                          <option value="1">AFE</option>
                          <option value="2">CECO</option>
                        </select>
                      </div>
                      <div class="form-group col-md-2">
                        <label for="proveedor">Numero PR</label>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.numero_pr"
                        />
                      </div>
                      <div class="form-group col-md-2">
                        <label for="remolque">Numero OT</label>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.numero_ot"
                        />
                      </div>

                      <div class="form-group col-md-2">
                        <label for="conductor">Fecha Aceptación</label>
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_aprobacion"
                        />
                      </div>

                      <div class="form-group col-md-2">
                        <label for="transportadora">Numero Aceptación</label>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.numero_aprobacion"
                        />
                      </div>
                      <div class="col-md-1">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn bg-navy mt-4"
                            @click="cargarDetFacturacion()"
                          >
                            <i class="fas fa-search"></i><br />Buscar
                          </button>
                        </div>
                      </div>
                      <div class="col-md-1">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-danger mt-4"
                            @click="limpiar()"
                          >
                            <i class="fas fa-broom"></i><br />Limpiar
                          </button>
                        </div>
                      </div>
                      <div class="col-md-1">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-success mt-4"
                            @click="exportExcel()"
                            v-if="
                              detFacturas.length > 0 && detFacturas !== null
                            "
                          >
                            <i class="fas fa-file-excel"></i><br />Excel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body table-responsive p-0 ">
                  <table
                    class="
                  table table-bordered table-striped table-hover table-sm
                "
                    style="font-size: 10px"
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Fecha de revision</th>
                        <th>
                          Contrato
                        </th>
                        <th>
                          Proveedor
                        </th>
                        <th>Bloque</th>
                        <th>
                          Campo
                        </th>
                        <th>
                          Activo
                        </th>
                        <th>
                          Chequera
                        </th>
                        <th>
                          Ceco/Afe
                        </th>
                        <th>Fecha inicio(Servicio)</th>
                        <th>Fecha final(Servicio)</th>
                        <th>Texto cabecera</th>
                        <th>Ot sin IVA</th>
                        <th>IVA</th>
                        <th>Ot con IVA</th>
                        <th>
                          Numero PR
                        </th>
                        <th>
                          Numero OT
                        </th>
                        <th>
                          Fecha aceptación
                        </th>
                        <th>
                          Aceptación N°
                        </th>
                        <th>Comentarios</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in detFacturas" :key="item.id">
                        <td class="text-center">{{ item.id }}</td>
                        <td class="text-nowrap">{{ item.fecha_revision }}</td>
                        <td class="text-nowrap">
                          {{ item.tep_contrato.no_contrato }}
                        </td>
                        <td class="text-nowrap">
                          {{ item.empresa.razon_social }}
                        </td>
                        <td class="text-nowrap">
                          {{ item.sitio.bloque.nombre }}
                        </td>
                        <td class="text-nowrap">{{ item.sitio.nombre }}</td>
                        <td class="text-nowrap">{{ item.sitio.nActivo }}</td>
                        <td class="text-nowrap ">
                          <span
                            v-if="formActualizarDet && idActualizarDet == index"
                          >
                            <input
                              type="text"
                              class="form-control form-control-sm p-0"
                              id="chequera"
                              v-model="formDetFacturacion.chequera"
                              style="width:70px; height: 1.7rem; font-size: 10px;"
                            />
                          </span>
                          <span v-else>{{ item.chequera }}</span>
                        </td>
                        <td class="text-nowrap">{{ item.ceco_afe }}</td>
                        <td class="text-nowrap">{{ item.fecha_inicio }}</td>
                        <td class="text-nowrap">{{ item.fecha_fin }}</td>
                        <td class="text-nowrap ">
                          <span
                            v-if="formActualizarDet && idActualizarDet == index"
                          >
                            <input
                              type="text"
                              class="form-control form-control-sm p-0"
                              id="descripcion"
                              v-model="formDetFacturacion.descripcion"
                              style="width:150px; height: 1.7rem; font-size: 10px;"
                            />
                          </span>
                          <span v-else>{{ item.descripcion }}</span>
                        </td>
                        <td class="text-right ">{{ item.valor }}</td>
                        <td class="text-right">{{ item.valor_iva }}</td>
                        <td class="text-right table-warning">
                          {{ item.valor_con_iva }}
                        </td>
                        <td class="text-right">
                          <span
                            v-if="formActualizarDet && idActualizarDet == index"
                          >
                            <input
                              type="number"
                              class="form-control form-control-sm"
                              id="numero_pr"
                              v-model="formDetFacturacion.numero_pr"
                              style="width:70px; height: 1.7rem; font-size: 10px;"
                            />
                          </span>
                          <span v-else>{{ item.numero_pr }}</span>
                        </td>
                        <td class="text-right ">
                          <span
                            v-if="formActualizarDet && idActualizarDet == index"
                          >
                            <input
                              type="number"
                              class="form-control form-control-sm"
                              id="numero_ot"
                              v-model="formDetFacturacion.numero_ot"
                              style="width:70px; height: 1.7rem; font-size: 10px;"
                            />
                          </span>
                          <span v-else>{{ item.numero_ot }}</span>
                        </td>
                        <td class="text-right ">
                          <span
                            v-if="formActualizarDet && idActualizarDet == index"
                          >
                            <input
                              type="date"
                              class="form-control form-control-sm"
                              id="fecha_aprobacion"
                              v-model="formDetFacturacion.fecha_aprobacion"
                              style="width:120px; height: 1.7rem; font-size: 10px;"
                            />
                          </span>
                          <span v-else>{{ item.fecha_aprobacion }}</span>
                        </td>
                        <td class="text-right ">
                          <span
                            v-if="formActualizarDet && idActualizarDet == index"
                          >
                            <input
                              type="number"
                              class="form-control form-control-sm"
                              id="numero_aprobacion"
                              v-model="formDetFacturacion.numero_aprobacion"
                              style="width:70px; height: 1.7rem; font-size: 10px;"
                            />
                          </span>
                          <span v-else>{{ item.numero_aprobacion }}</span>
                        </td>
                        <td class="text-right ">
                          <span
                            v-if="formActualizarDet && idActualizarDet == index"
                          >
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              id="comentarios"
                              v-model="formDetFacturacion.comentarios"
                              style="width:150px; height: 1.7rem; font-size: 10px;"
                            />
                          </span>
                          <span v-else>{{ item.comentarios }}</span>
                        </td>
                        <td>
                          <div
                            v-if="formActualizarDet && idActualizarDet == index"
                          >
                            <div class="btn-group float-right">
                              <button
                                type="button"
                                class="btn btn-sm bg-success px-1 py-0"
                                @click="guardarActualizarDetFacturacion(index)"
                              >
                                <i class="fas fa-check-square"></i>
                              </button>
                              <button
                                type="button"
                                class="btn btn-sm bg-danger px-1 py-0"
                                @click="cancelarActualizarDetFacturacion(index)"
                              >
                                <i class="fas fa-trash"></i>
                              </button>
                            </div>
                          </div>

                          <span v-else>
                            <!-- Botón para mostrar el formulario de actualizar -->
                            <button
                              type="button"
                              class="btn btn-sm bg-primary px-1 py-0"
                              @click="verFormActualizarDetFacturacion(index)"
                            >
                              <i class="fas fa-pen-square"></i>
                            </button>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="12" class="text-right">TOTALES:</td>

                        <td
                          class="text-right table-warning"
                          v-if="totales != null"
                        >
                          {{ totales[0].total.toFixed(2) }}
                        </td>
                        <td v-if="totales != null">19</td>
                        <td
                          class="text-right table-warning"
                          v-if="totales != null"
                        >
                          {{ totales[0].total_con_iva.toFixed(2) }}
                        </td>

                        <td class="text-right" v-if="totales == null">0</td>
                        <td v-if="totales == null">19</td>
                        <td class="text-right" v-if="totales == null">
                          0
                        </td>
                        <td colspan="5"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="card-footer">
                  <div v-if="detFacturas !== null && detFacturas !== undefined">
                    <div class="float-left" v-if="detFacturas.length > 0">
                      <p>
                        Mostrando del <b>1</b> al
                        <b>{{ detFacturas.length }}</b> de un total:
                        <b>{{ detFacturas.length }}</b> Registros
                      </p>
                    </div>
                  </div>

                  <div class="float-left" v-else>
                    <p>
                      <span class="badge badge-danger">
                        No hay registros para mostrar
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import { required, minLength, email } from "vuelidate/lib/validators";

import Loading from "../../../../components/Loading";

export default {
  name: "TepFacturacionForm",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      definitivo: 0,
      detFacturas: {},
      totales: null,
      form: {
        fecha_inicio: null,
        fecha_fin: null,
        nombre: null,
        estado: null,
        observaciones: null,
      },
      filtros: { id: null },
      sitio: [],
      transportadora: [],
      formActualizarDet: false,
      idActualizarDet: -1,
      formDetFacturacion: {
        id: null,
        chequera: null,
        descripcion: null,
        numero_pr: null,
        numero_ot: null,
        fecha_aprobacion: null,
        numero_aprobacion: null,
        comentarios: null,
      },
      estadoActualizar: 0,

      accion: "",
      metodo: "",
      error: "",
      listasForms: {
        estados: [],
        transportadoras: [],
        sitios: [],
        activos: [],
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  validations: {
    form: {
      fecha_inicio: {
        required,
      },
      fecha_fin: {
        required,
      },
      estado: {
        required,
      },
      nombre: {
        required,
      },
    },
  },
  methods: {
    back() {
      return this.$router.replace("/Tep/Facturacion");
    },

    save() {
      if (!this.$v.form.$invalid) {
        axios({
          method: this.metodo,
          url: "/api/tep/facturacion",
          data: this.form,
        })
          .then(async (response) => {
            this.form = response.data;
            this.filtros.id = this.form.id;
            await this.cargarDetFacturacion();
            if (this.form.estado == 2) {
              this.definitivo = 1;
            }
            (this.id = this.form.id),
              this.$swal({
                icon: "success",
                title: "El contrato se guardo exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    init() {
      this.accion = this.$route.params.accion;
      if (this.$route.params.accion == "Editar") {
        this.form = this.$route.params.data_edit;
        this.filtros.id = this.$route.params.data_edit.id;
        this.metodo = "PUT";
      } else {
        this.metodo = "POST";
      }
      if (this.form.estado == 2) {
        this.definitivo = 1;
      }
    },

    validaFechas() {
      if (this.form.fecha_inicio && this.form.fecha_fin) {
        const fecha_menor = new Date(this.form.fecha_inicio);
        const fecha_mayor = new Date(this.form.fecha_fin);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    buscarSitio(search) {
      let me = this;
      this.listasForms.sitios = [];
      if (search != "") {
        var url = "api/admin/sitios/lista?nombre=" + search;
        axios
          .get(url)
          .then(function(response) {
            me.listasForms.sitios = response.data;
          })
          .catch(function(e) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectSitio() {
      this.filtros.sitio_id = "";
      if (this.sitio) {
        this.filtros.sitio_id = this.sitio.id;
      }
    },

    buscarTransportadoras(search) {
      let me = this;
      me.listasForms.transportadoras = [];
      if (search != "") {
        var url = "api/admin/empresas/lista?razon=" + search;
        axios
          .get(url, {
            params: {
              //empresa_id: this.empresa_id
            },
          })
          .then(function(response) {
            me.listasForms.transportadoras = response.data;
          })
          .catch(function(e) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    async selectTransportadora() {
      this.filtros.transportadora_id = "";
      if (this.transportadora) {
        this.filtros.transportadora_id = this.transportadora.id;
      }
    },

    async cargarDetFacturacion() {
      axios
        .get("/api/tep/cargarDetFacturacion", {
          params: this.filtros,
        })
        .then((response) => {
          this.detFacturas = response.data.tepDetFacturacion;
          this.totales = response.data.totales;
        });
    },

    getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    getActivos() {
      axios.get("/api/lista/117").then((response) => {
        this.listasForms.activos = response.data;
      });
    },

    limpiar() {
      this.filtros.activo = "";
      this.filtros.ceco = "";
      this.filtros.chequera = "";
      this.filtros.contrato = "";
      this.filtros.sitio_id = "";
      this.filtros.transportadora_id = "";
      this.filtros.numero_pr = "";
      this.filtros.numero_ot = "";
      this.filtros.numero_aceptacion = "";
      this.transportadora = [];
      this.sitio = [];

      this.cargarDetFacturacion();
    },

    verFormActualizarDetFacturacion(detFacturacion_id) {
      if (this.estadoActualizar == 1) {
        this.$swal({
          icon: "error",
          title:
            "Se debe actualizar o descartar el registro actual, antes de realizar otro cambio.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        this.idActualizarDet = detFacturacion_id;
        this.formDetFacturacion.id = this.detFacturas[detFacturacion_id].id;
        this.formDetFacturacion.chequera = this.detFacturas[
          detFacturacion_id
        ].chequera;
        this.formDetFacturacion.descripcion = this.detFacturas[
          detFacturacion_id
        ].descripcion;
        this.formDetFacturacion.numero_pr = this.detFacturas[
          detFacturacion_id
        ].numero_pr;
        this.formDetFacturacion.numero_ot = this.detFacturas[
          detFacturacion_id
        ].numero_ot;
        this.formDetFacturacion.fecha_aprobacion = this.detFacturas[
          detFacturacion_id
        ].fecha_aprobacion;
        this.formDetFacturacion.numero_aprobacion = this.detFacturas[
          detFacturacion_id
        ].numero_aprobacion;
        this.formDetFacturacion.comentarios = this.detFacturas[
          detFacturacion_id
        ].comentarios;

        this.formActualizarDet = true;
        this.estadoActualizar = 1;
      }
    },

    async guardarActualizarDetFacturacion() {
      axios({
        method: "POST",
        url: "/api/tep/detFacturacion/actualizarDetFacturacion",
        data: this.formDetFacturacion,
      })
        .then(async (response) => {
          this.$swal({
            icon: "success",
            title: "Se actualizo exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.idActualizarDet = -1;
          this.formActualizarDet = false;
          this.formDetFacturacion = {
            id: null,
            chequera: null,
            descripcion: null,
            numero_pr: null,
            numero_ot: null,
            fecha_aprobacion: null,
            numero_aprobacion: null,
            comentarios: null,
          };
          this.estadoActualizar = 0;
          await this.cargarDetFacturacion();
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
    cancelarActualizarDetFacturacion() {
      this.idActualizarDet = -1;
      this.formActualizarDet = false;
      this.formDetFacturacion = {
        id: null,
        chequera: null,
        descripcion: null,
        numero_pr: null,
        numero_ot: null,
        fecha_aprobacion: null,
        numero_aprobacion: null,
        comentarios: null,
      };
      this.estadoActualizar = 0;
    },

    exportExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/tep/detFacturacion/excel",
        data: this.filtros,
      })
        .then((response) => {
          let data = response.data;
          location.href = data.url;
          this.cargando = false;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error - " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    await this.init();
    await this.getEstados();
    await this.cargarDetFacturacion();
    await this.getActivos();
  },
};
</script>
<style>
.button-container {
  display: inline-block;
  position: relative;
}

.button-container a {
  bottom: 3em;
  right: 4em;
  color: white;
  text-transform: uppercase;
  padding: 1em 1.8em;
  opacity: 0.3;
  color: #fff !important;
  background: #ff0000;
  position: absolute;
  outline: 2px solid;
  outline-color: rgba(248, 8, 8, 0.89);
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.button-container a:hover {
  box-shadow: inset 0 0 20px rgba(248, 8, 8, 0.89),
    0 0 20px rgba(248, 8, 8, 0.89);
  outline-color: rgba(49, 138, 172, 0);
  outline-offset: 80px;
  text-shadow: 3px 3px 3px #fff;
}
</style>
